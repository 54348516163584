import PropTypes from "prop-types";
import React from "react";
import { useParams } from "react-router-dom";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import BlogItemContainer from "../containers/blog/blog-item";
import PageBanner from "../containers/global/page-banner";
import BlogData from "../data/blog.json";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import { slugify } from "../utils";

const BlogDate = () => {
    const { date } = useParams();
    const data = BlogData.filter((blog) => slugify(blog.date) === date);
    const dateTitle = data[0].date;
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Glamdietitian – Blog Date" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title={dateTitle}
                        excerpt="Pleasure rationally encounter consequences <br /> are extremely painful great opportunity "
                        image="/images/blog/banner.png"
                    />
                    <BlogItemContainer data={data} />
                    {/* <NewsletterArea /> */}
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

BlogDate.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            date: PropTypes.string,
        }),
    }),
};

export default BlogDate;
