import React, { useEffect, useState } from 'react';

const LazyLoadedYouTube = () => {
    const [videoLoaded, setVideoLoaded] = useState(false);

    const loadYouTubeVideo = () => {
        setVideoLoaded(true);
    };

    useEffect(() => {
        let timeoutId;

        // Set a timeout to load the video after a delay (e.g., 5 seconds)
        timeoutId = setTimeout(() => {
            setVideoLoaded(true);
        }, 3000); // Adjust the delay in milliseconds as needed

        return () => {
            // Clear the timeout if the component is unmounted before the delay completes
            clearTimeout(timeoutId);
        };
    }, []);
    return (
        <div className="youtube-container" >
            <>
                {videoLoaded ? (
                    <iframe
                        width="100%"
                        height="360"
                        src="https://www.youtube.com/embed/OySB13pbWK8"
                        title="YouTube Video"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                ) : (
                    <p onClick={loadYouTubeVideo}>Click to load YouTube video</p>
                )}
            </>
        </div>
    );
};

export default LazyLoadedYouTube;
