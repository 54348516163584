/* eslint-disable jsx-a11y/alt-text */
import Parallax from "parallax-js";
import React, { useEffect, useRef } from "react";
import Intro from "../../../components/intro";
import HomeData from "../../../data/home.json";

const IntroContainer = () => {
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div className="section position-relative">
            <div className="hero-shape1" style={{ visibility: "hidden" }}>
                <img
                    src={`${process.env.PUBLIC_URL}/images/slider/shape/shape1.png`}
                    alt="shape"
                />
            </div>
            <div className="mt-lg-0 hero-image-wrapper">
                <div
                    className="hero-img scene  mt-lg-0"
                    id="scene"
                    ref={sceneEl}
                ><div data-depth="0.2">
                        <img
                            className="animated"
                            src={`${process.env.PUBLIC_URL}${HomeData[0].slider[0].image}`}
                            alt="Into"
                        />
                    </div>
                </div>
            </div>
            <div className="hero-shape2" style={{ visibility: "hidden" }}>
                <img
                    src={`${process.env.PUBLIC_URL}/images/slider/shape/shape2.png`}
                    alt="shape"
                />
            </div>
            <Intro data={HomeData[0].slider[0]} />
        </div>
    );
};

export default IntroContainer;
