import PropTypes from "prop-types";

const Intro = ({ data }) => {

    return (
        <div className="hero-slider">
            <div className="container">
                <div className="row align-items-end justify-content-end">
                    <div className="col-lg-6 home-content-wrapper">
                        <div className="hero-slide-content">
                            <h1 className="title title1 animated">"Glamup - Eat Smart" </h1>
                            <h2
                                className="title animated"
                                dangerouslySetInnerHTML={{ __html: data.title }}
                            ></h2>
                            {/* <Button
                                classOption="btn btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0"
                                text="Get Started"
                                path="/contact"
                            /> */}
                            {/* <Button
                                classOption="btn btn-lg animated delay2 btn-secondary btn-hover-secondary mb-3 mb-sm-0"
                                text="Book a Meeting"
                                path="/contact"
                            /> */}
                        </div>
                    </div>
                    {/* <div className="col-lg-6">
                        <div
                            className="hero-img scene mt-10 mt-lg-0"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div data-depth="0.2">
                                <img
                                    className="animated"
                                    src={`${process.env.PUBLIC_URL}${data.image}`}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

Intro.propTypes = {
    data: PropTypes.object,
};

export default Intro;
