import React from "react";
import Accordion, {
    AccordionContent,
    AccordionItem,
    AccordionTitle,
} from "../accrodion";


const AccordionWrap = () => {
    return (
        <Accordion classOption="accordion-style2 no-bg">
            <AccordionItem id="one">
                <AccordionTitle id="one">
                    <span className="question-tag">Q:</span>What diet services do you offer?
                </AccordionTitle>
                <AccordionContent id="one">
                    We offer a range of diet services tailored to your specific needs. Our services include personalized nutrition plans, weight management, and assistance with dietary concerns.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="two">
                <AccordionTitle id="two">
                    <span className="question-tag">Q:</span>Can you help with hair-related issues?
                </AccordionTitle>
                <AccordionContent id="two">
                    While we primarily focus on diet, we also offer guidance on maintaining healthy hair through nutrition. For specialized hair services, we recommend consulting with our experienced hairstylists.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="three">
                <AccordionTitle id="three">
                    <span className="question-tag">Q:</span>What skincare treatments do you provide?
                </AccordionTitle>
                <AccordionContent id="three">
                    Our skincare services encompass a variety of treatments, including facials, acne treatments, and anti-aging procedures. We offer personalized solutions for various skin types and concerns.
                </AccordionContent>
            </AccordionItem>
            {/* Add more questions related to your services here */}
        </Accordion>
    );
};

export default AccordionWrap;
