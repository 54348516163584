import Parallax from "parallax-js";
import { useEffect, useRef } from "react";
import Button from "../../../components/button";
import SectionTitle from "../../../components/section-title";

const HomeAboutContainer = () => {
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div className="about-us position-relative">
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-5 col-lg-6 mb-7">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                subTitle="ABOUT US"
                                title="Know your 
                                <span class='text-primary'>
                                Glamdietititan
                                </span>
                                <br className='d-none d-xl-block' />"
                            />
                            <span className="date-meta">
                                Since 2014 <span className="hr"></span>
                            </span>
                            <p className="high-light mb-5" style={{ fontSize: "20px", color: "#250b0b" }}>
                                Introducing <strong>Anita Harisinghani </strong>, a seasoned clinical dietitian with over a <strong>decade</strong>  of expertise in the field.

                            </p>
                            <p className="high-light mb-8">
                                Anita holds a postgraduate degree in Food and Nutrition from BMN College and is also a certified cosmetologist and trichologist.
                                Her multifaceted qualifications and years of experience make her a valuable resource in the realms of nutrition, beauty, and hair care.
                            </p>

                        </div>
                    </div>
                    <div className="col-xl-6 order-first order-lg-last col-lg-6 mb-7 offset-xl-1">
                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div data-depth="0.2">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/about/1.png`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <p className="high-light">Dynamic and knowledgeable dietitian since 2014, dedicated to promoting health and wellbeing through evidence-based nutritional guidance. With a strong foundation in nutritional science and its practical applications, "I have effectively assisted diverse clients in achieving their wellness goals. Through comprehensive assessments, I develop personalized meal plans that prioritize balanced nutrition while considering individual preferences and dietary restrictions. My communication skills empower me to educate and motivate clients to make informed dietary choices, thereby facilitating sustainable lifestyle changes. Driven by a passion for continuous learning, I am constantly seeking to expand my expertise in the ever-evolving field of nutrition to deliver the highest quality care.
                    Engaging in hands-on practice across a broad spectrum of personalized skin and haircare treatments, offering exceptional services that not only transform appearances but also enhance confidence".   <strong style={{ float: 'right' }}>--Anita Harisinghani</strong></p>

                <Button
                    classOption="btn btn-lg btn-dark btn-hover-dark"
                    text="Know more about us"
                    path="/about"
                />
            </div>
            <img
                className="about-shape"
                src={`${process.env.PUBLIC_URL}/images/about/shape.png`}
                alt="bg-shape"
                style={{ visibility: "hidden" }}
            />
        </div>
    );
};

export default HomeAboutContainer;
