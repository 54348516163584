import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import ContactContainer from "../containers/contact";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";

const ContactPage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Glamdietitian – Contact" />
                <div className="wrapper">
                    <Header />
                    {/* <PageBanner
                        title="Contact us"
                        excerpt="Connect with Us for a Healthier You!"
                        image={`/images/contact/1.webp`}

                    /> */}
                    <ContactContainer />
                    {/* <NewsletterArea /> */}
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ContactPage;
