import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import FunFactContainer from "../containers/global/funfact";
import PageBanner from "../containers/global/page-banner";
import TestimonialReverse from "../containers/global/testimonial-reverse";
import ServiceListContainer from "../containers/service/service-list";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";

const AboutPage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Glamdietitian – About" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="About us"
                        excerpt="Glam Dietitian focuses not only on providing guidance for health and wellness through dietary choices but also emphasizes the importance of maintaining a glamorous and stylish lifestyle. This might involve offering advice on beauty-enhancing foods, skincare routines, and other elements that contribute to an overall glamorous appearance and well-being."
                        image="/images/banner/1.png"
                    />
                    {/* <IconBoxContainer classOption="section-pt" /> */}
                    {/* <BrandContainer /> */}
                    {/* <AboutContainer /> */}
                    <ServiceListContainer />
                    <TestimonialReverse />
                    <FunFactContainer classOption="mt-0 mt-lg-0" />
                    {/* <NewsletterArea /> */}
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default AboutPage;
