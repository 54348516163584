import PropTypes from "prop-types";
import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";

// Import other components and containers
import { useParams } from "react-router-dom";
import PageBanner from "../containers/global/page-banner";
import ServiceDetailsContainer from "../containers/service/service-details";
import ServiceData from "../data/service.json";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";

const ServiceDetails = () => {
    const { id } = useParams();
    const serviceId = parseInt(id, 10);
    const data = ServiceData.filter((service) => service.id === serviceId);
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Glamdietitian – Service Details" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title={data[0]?.title}
                        excerpt={data[0]?.excerpt}
                        image={data[0]?.bannerImage}
                    />
                    <ServiceDetailsContainer data={data[0]} />
                    {/* <NewsletterArea /> */}
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

ServiceDetails.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    }),
};

export default ServiceDetails;
