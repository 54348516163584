import React from "react";
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
// import "swiper/css/swiper.min.css";
import 'swiper/css';
import { register } from 'swiper/element/bundle';
import "./assets/css/plugins/animate.min.css";
import "./assets/css/vendor/font-awesome.css";
import "./assets/css/vendor/icofont.min.css";
import "./assets/css/vendor/material-design-iconic.min.css";
import "./assets/css/vendor/metropolis.css";
import "./assets/scss/style.scss";
import NavScrollTop from "./components/nav-scroll-top";
import AboutPage from "./pages/about";
import BlogPage from "./pages/blog";
import BlogLeftSidebarPage from "./pages/blog-left-sidebar";
import BlogRightSidebarPage from "./pages/blog-right-sidebar";
import ContactPage from "./pages/contact";
import FaqPage from "./pages/faq";
import HomePage from "./pages/index";
import ServicePage from "./pages/service";
import TeamPage from "./pages/team";
import BlogAuthor from "./templates/blog-author";
import BlogCategory from "./templates/blog-category";
import BlogDate from "./templates/blog-date";
import BlogDetailsPage from "./templates/blog-details";
import BlogTag from "./templates/blog-tag";
import ServiceDetails from "./templates/service-details";
const App = () => {
  register();
  return (
    <Router>
      <NavScrollTop>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/service" element={<ServicePage />} />
          <Route path="/service-details/:id" element={<ServiceDetails />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog-left-sidebar" element={<BlogLeftSidebarPage />} />
          <Route path="/blog-right-sidebar" element={<BlogRightSidebarPage />} />
          <Route path="/blog-details/:id" element={<BlogDetailsPage />} />
          <Route path="/author/:author" element={<BlogAuthor />} />
          <Route path="/date/:date" element={<BlogDate />} />
          <Route path="/tag/:slug" element={<BlogTag />} />
          <Route path="/category/:slug" element={<BlogCategory />} />
        </Routes>
      </NavScrollTop>
    </Router>
  );
};

export default App;
