import React from "react";
import SectionTitle from "../../../components/section-title";
import ServiceBox from "../../../components/service-box";
import ServiceData from "../../../data/service.json";
import LazyLoadedYouTube from "./LazyLoadedYoutube";

const ServiceListContainer = () => {
    return (
        <div className="service-section section-pt position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-8 mx-auto">
                        {/* <div className="service-media-img text-center">
                            <video width="400" height="360" controls>
                                <source src={`${process.env.PUBLIC_URL}/videos/intro.mp4`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div> */}
                        <SectionTitle
                            classOption="title-section mb-10 pb-10 text-center"
                            subTitle=""
                            title="Check <span class='text-primary'>our</span> Services"
                            excerptClassOption="null"
                            excerpt=""
                        />

                    </div>
                </div>

                <div className="row mb-n7 align-items-center">
                    <div className="col-md-6 col-xl-4 mb-7">
                        <div className="service-media-wrapper media-spacing-left">
                            {ServiceData &&
                                ServiceData.slice(0, 6).map((single, key) => {
                                    return (
                                        <ServiceBox data={single} key={key} />
                                    );
                                })}
                        </div>
                    </div>

                    <div className="col-xl-4 mb-7 order-md-1 order-xl-0">
                        {/* <div className="service-media-img text-center">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/service/media.png`}
                                alt="images_not_found"
                            />
                        </div> */}
                        <div className="service-media-img text-center">
                            {/* <video width="400" height="360" controls>
                                <source src={`${process.env.PUBLIC_URL}/videos/intro.mp4`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video> */}
                            {/* <iframe
                                width="100%"
                                height="360"
                                src="https://www.youtube.com/embed/OySB13pbWK8"
                                title="YouTube Video"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe> */}
                            <LazyLoadedYouTube></LazyLoadedYouTube>
                        </div>
                        <br />

                        <br />
                        <div className="service-media-img text-center hide-mobile">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/service/media.webp`}
                                alt="images_not_found"
                            />
                        </div>
                        <br />
                        <br />
                        <div className="service-media-img text-center hide-mobile">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/service/media2.webp`}
                                alt="images_not_found"
                            />
                        </div>
                    </div>


                    <div className="col-md-6 col-xl-4 mb-7">
                        <div className="service-media-wrapper media-spacing-right">
                            {ServiceData &&
                                ServiceData.slice(6, 12).map((single, key) => {
                                    return (
                                        <ServiceBox data={single} key={key} />
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceListContainer;
